import { Box, ButtonBase, Dialog, Grid, LinearProgress, LinearProgressProps, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import QualityCheckFileUploadPanel from "../components/QualityCheckFileUploadPanel";
import { useEffect, useState } from "react";
import qualityCheckApi, { GlossaryItem } from "store/apis/qualityCheckApi";
import CreateGlossaryPanel from "../components/CreateGlossaryPanel";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { stompClient } from "shared/StompClient";
import { JobStatusUpdateMessage } from "types/models/JobStatusUpdateMessage";
import { IMessage } from "@stomp/stompjs";
import { JobStatus } from "types/models/JobStatus";

type BuildGlossaryPanelProps = {
    isOpen: boolean
    onClose: () => void
    expandMyGlossary: () => void
}

const statusProgressMapping: { [key: string]: number } = {
    "PENDING": 0,
    "IN_PROGRESS": 20,
    "RUNNING_RETRIEVE_DOC": 40,
    "RUNNING_PARAGRAPH_SEGMENTATION": 40,
    "RUNNING_ALIGNMENT": 60,
    "RUNNING_EXTRACTION": 80,
    "COMPLETE": 100,
}

const BuildGlossaryPanel = (props: BuildGlossaryPanelProps) => {

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    //api
    const { data: user } = qualityCheckApi.useQualityCheckJobUserProfileQuery();
    const [extractGlossary, { data: textData }] = qualityCheckApi.useQualityCheckGlossaryExtractMutation();
    const [extractGlossaryDoc, { data: docData }] = qualityCheckApi.useQualityCheckGlossaryExtractDocMutation();
    const [initialChecking, { data: wordCount, isSuccess: isInitialCheckingSuccess, isLoading: isInitialChecking }] = qualityCheckApi.useQualityCheckInitialCheckingMutation();
    const [getGlossaryExtractResult] = qualityCheckApi.useQualityCheckGlossaryExtractResultMutation();

    //panel state
    const [jobId, setJobId] = useState(-1);
    const [status, setStatus] = useState("");
    const [progress, setProgress] = useState(0);
    const [inputType, setInputType] = useState<string | null>("text");
    const [textInput, setTextInput] = useState({ enP: "", zhP: "" });
    const [isOpenGlossaryDialog, setIsOpenGlossaryDialog] = useState(false);
    const [extractedGlossary, setExtractedGlossary] = useState<GlossaryItem[]>([]);
    const [connected, setConnected] = useState(false);

    const [docCreditUsage, setDocCreditUsage] = useState(-1);
    const [textCreditUsage, setTextCreditUsage] = useState(-1);

    const [fileInput, setFileInput] = useState({
        enFile: [] as File[],
        zhFile: [] as File[],
    });

    //start initial check 
    useEffect(() => {
        if (textInput.enP.length !== 0 || textInput.zhP.length !== 0) {
            const enText = textInput.enP.match(/[^\u0000-\u2E7F]|[a-zA-Z0-9]+/g)?.length || 0;
            const zhText = textInput.zhP.match(/[^\u0000-\u2E7F]|[a-zA-Z0-9]+/g)?.length || 0;
            setTextCreditUsage(enText + zhText);
        } else {
            setTextCreditUsage(-1);
        }
        if (fileInput.enFile.length !== 0 && fileInput.zhFile.length !== 0) {
            initialChecking({ sourceFile: fileInput.enFile[0], targetFile: fileInput.zhFile[0] });
        } else {
            setDocCreditUsage(-1);
        }
        setJobId(-1);
    }, [textInput, fileInput])

    //set credit usage
    useEffect(() => {
        if (isInitialCheckingSuccess) {
            if (wordCount) {
                setDocCreditUsage(wordCount.sourceWordCount + wordCount.targetWordCount);
            }
        }
    }, [isInitialCheckingSuccess])

    //listen to job status
    useEffect(() => {
        if (jobId !== -1) {
            stompClient.subscribe("/queue/job-result/glossary/" + jobId, async (msg: IMessage) => {
                const message: JobStatusUpdateMessage = JSON.parse(msg.body);
                const status = message.status;
                setStatus(status)
                if (message.status.includes("ERROR")) {
                    setConnected(false);
                    stompClient.deactivate();
                    toast.error(message.status)
                    return
                }
                setProgress(statusProgressMapping[status]);
                if (message.status === JobStatus.COMPLETE) {
                    stompClient.deactivate();
                    setConnected(false);
                    getGlossaryExtractResult({ uid: message.uuid }).unwrap().then((data: GlossaryItem[]) => {
                        setExtractedGlossary(data);
                        setIsOpenGlossaryDialog(true);
                    })
                    toast.success("Build glossary success!")
                }
            })
        }
    }, [jobId])

    const handleClosePanel = () => {
        props.onClose();
        setFileInput({ enFile: [], zhFile: [] });
        setTextInput({ enP: "", zhP: "" });
        setJobId(-1);
        setConnected(false);
    }

    const createUploadHandler = (type: string) => (file: File[]) => {
        setFileInput(prevState => ({
            ...prevState,
            [`${type}File`]: file,
        }));
    };

    const handleChangeInputType = (event: React.MouseEvent<HTMLElement>, newInputType: string | null) => {
        if (newInputType !== null) {
            setInputType(newInputType);
        }
    }

    const handleSubmitGlossaryExtract = async () => {
        setProgress(0)
        setStatus("Pending...")
        if (isLoggedIn) {
            if (user?.subscriptionStatus === "INACTIVE") {
                if (user?.creditBalance < (inputType === "text" ? textCreditUsage : docCreditUsage)) {
                    toast.error("Insufficient credit balance, please top-up or subscribe Addcuracy+ to continue!")
                    return;
                }
            }
        } else {
            window.location.href = "/login"
            return;
        }
        stompClient.activate()
        setConnected(true)
        const id = toast.loading("Pending glossary extract...")
        if (inputType === "text") {
            await extractGlossary(textInput).unwrap().then((data) => {
                toast.update(id, { render: "Extract glossary job submit successfully!", type: "success", isLoading: false, autoClose: 2000 })
                setJobId(data.jobId)
            }).catch(() => {
                toast.update(id, { render: "Extract glossary job submit failed!", type: "error", isLoading: false, autoClose: 2000 })
            });
        } else {
            await extractGlossaryDoc({ enFile: fileInput.enFile[0], zhFile: fileInput.zhFile[0] }).unwrap().then((data) => {
                toast.update(id, { render: "Extract glossary job submit successfully!", type: "success", isLoading: false, autoClose: 2000 })
                setJobId(data.jobId)
            }).catch(() => {
                toast.update(id, { render: "Extract glossary job submit failed!", type: "error", isLoading: false, autoClose: 2000 })
            });
        }
    }

    const textFieldZone = (type: string) => {

        const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (type === "Source") {
                setTextInput({ ...textInput, enP: event.target.value })
            }
            else {
                setTextInput({ ...textInput, zhP: event.target.value })
            }
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    <Typography variant="h6" id="modal-title">
                        {type}
                    </Typography>
                    <TextField
                        multiline
                        variant="outlined"
                        fullWidth
                        rows={8}
                        margin="normal"
                        onChange={handleTextChange}
                        inputProps={{ style: { fontSize: "10px" } }}
                        value={type === "Source" ? textInput.enP : textInput.zhP}
                    />
                </Grid>
            </Grid>
        )
    }

    const LinearProgressWithLable = (props: LinearProgressProps & { value: number }) => {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Dialog
            open={props.isOpen}
            onBackdropClick={handleClosePanel}
            maxWidth={"md"}
            PaperProps={{ sx: { borderRadius: "10px", padding: "20px", height: "fitContent", justifyContent: "space-between" } }}
        >
            <Grid container spacing={2} height={"maxContent"}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <Typography variant="h5" gutterBottom>
                            {"Glossary Extraction Tool"}
                        </Typography>
                        <ButtonBase onClick={handleClosePanel}>
                            <CloseIcon />
                        </ButtonBase>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            {"Glossary Extraction Tool tries to build glossary from source and target file."}
                        </Typography>
                        <ToggleButtonGroup
                            value={inputType}
                            onChange={handleChangeInputType}
                            exclusive
                            sx={{ height: "35px" }}
                        >
                            <ToggleButton value="text">
                                Text
                            </ToggleButton>
                            <ToggleButton value="file">
                                File
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingTop: "10px", paddingLeft: "32px" }}>
                    {inputType === "file" ?
                        <QualityCheckFileUploadPanel
                            title="Upload source file"
                            document={fileInput.enFile}
                            onDrop={createUploadHandler('en')}
                        />
                        :
                        <Box height={274}>
                            {textFieldZone("Source")}
                        </Box>
                    }
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingTop: "10px", paddingLeft: "32px" }}>
                    {inputType === "file" ?
                        <QualityCheckFileUploadPanel
                            title="Upload target file"
                            document={fileInput.zhFile}
                            onDrop={createUploadHandler('zh')}
                        />
                        :
                        <Box height={274}>
                            {textFieldZone("Target")}
                        </Box>
                    }
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "10px", paddingLeft: "32px" }}>
                    {inputType === "file" ?
                        <Typography>
                            {"Credit Usage: "} {docCreditUsage !== -1 && docCreditUsage} {isInitialChecking && "calculating..."}
                        </Typography>
                        :
                        <Typography>
                            {"Credit Usage: "} {textCreditUsage !== -1 && textCreditUsage}
                        </Typography>
                    }
                </Grid>
            </Grid>
            <Box
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <LoadingButton
                    onClick={handleSubmitGlossaryExtract}
                    loading={connected}
                    disabled={
                        inputType === "text" ?
                            textInput.enP === "" || textInput.zhP === ""
                            :
                            fileInput.enFile.length === 0 || fileInput.zhFile.length === 0
                    }
                    sx={{ width: "fit-content" }}
                >
                    {"Generate"}
                </LoadingButton>
            </Box>
            {jobId !== -1 &&
                <Box sx={{ width: '100%' }}>
                    <Typography color={"gray"}>{status}</Typography>
                    <LinearProgressWithLable value={progress} />
                </Box>
            }
            {(textData || docData) &&
                <CreateGlossaryPanel
                    isOpen={isOpenGlossaryDialog}
                    glossary={extractedGlossary}
                    onClickClose={() => { setIsOpenGlossaryDialog(false); setExtractedGlossary([]); }}
                    expandMyGlossary={props.expandMyGlossary}
                    isBuildGlossary={true}
                />
            }
        </Dialog>

    )
}

export default BuildGlossaryPanel;