import { Button, Divider, Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { MouseEventHandler, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import qualityCheckApi from "store/apis/qualityCheckApi";
import microsoft from "../assets/img/microsoft.png"
import icon1 from "../assets/img/icon1.png"



const UserProfile = () => {

    const { data } = qualityCheckApi.useQualityCheckJobUserProfileQuery();

    const [displayName, setDisplayName] = useState(data?.displayName);

    const [handleOnClickRename] = qualityCheckApi.useQualityCheckUserRenameMutation();
    const [handleOnClickUnlink] = qualityCheckApi.useQualityCheckOAuth2UnlinkMutation();

    const {
        adLinkStatus, dtLinkStatus
    } = useMemo(() => {
        return {
            adLinkStatus: Object.keys(data?.linkedProvider ?? {}).includes("ad") ?? false,
            dtLinkStatus: Object.keys(data?.linkedProvider ?? {}).includes("dt") ?? false,
        }
    }, [data]);

    const renderLinkProviderButton = (linked: boolean, provider: string) => {
        if (linked) {
            const handleButtonOnClick: MouseEventHandler = async (event) => {
                await handleOnClickUnlink({
                    oAuth2Provider: provider,
                }).unwrap();
                toast.success("Unlinked successfully");
            }

            return (
                <Button onClick={handleButtonOnClick}>
                    {"Unlink"}
                </Button>
            )
        } else {
            return (
                <form method="POST" action={`/api/user/login/link/${provider}`}>
                    <Button type="submit">
                        {"link"}
                    </Button>
                </form>
            )
        }
    }

    const renderLinkAccountComponent = (provider: string) => {
        const icon = provider === "dt" ? icon1 : microsoft
        const displayProvider = provider === "dt" ? "Deeptranslate" : "Microsoft"
        const linkStatus = provider === "dt" ? dtLinkStatus : adLinkStatus
        const linkedEmail = provider === "dt" ? data?.linkedProvider.dt : data?.linkedProvider.ad
        return (
            <Box sx={{ display: "flex", flexDirection: "row", margin: "20px", alignItems: "center" }}>
                <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                    <img src={icon} alt={displayProvider} width={34} />
                    <Typography sx={{ marginLeft: "5px" }}>
                        {displayProvider}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Box>
                        <Typography>
                            {`Link your account to ${displayProvider}`}
                        </Typography>
                        <Typography>
                            {`Email: ${linkedEmail}`}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
                    {renderLinkProviderButton(linkStatus, provider)}
                </Grid>
            </Box>
        )
    }

    const handleSaveOnClick = async (name: any) => {
        await handleOnClickRename({
            name: name
        }).unwrap();
        toast.success("Save successfully")
    }

    const submitButtonDisabled = useMemo(() => {
        return !displayName || displayName === data?.displayName;
    }, [displayName, data]);

    return (
        <>
            <ToastContainer />
            <Box sx={{ height: "25px", display: "flex", alignItems: "end" }}>
                <Typography fontSize={25}>
                    {"User Profile"}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-start", maxWidth: "12%" }}>
                        <Typography fontSize={"19px"}>
                            {"Info"}
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid item xs={11} >
                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "50px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
                                <Typography>
                                    {"Display Name"}
                                </Typography>
                                <TextField
                                    value={displayName}
                                    onChange={(event) => setDisplayName(event.currentTarget.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
                                <Typography>
                                    {"Subscription Status"}
                                </Typography>
                                <TextField
                                    disabled
                                    value={data?.subscriptionStatus}
                                />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
                                <Typography>
                                    {"Expiry Date"}
                                </Typography>
                                <TextField
                                    disabled
                                    value={data?.subscriptionStatus === "ACTIVE" ? data?.expiryDate.toString() : "N/A"}
                                />
                            </Box>
                            <Button
                                disabled={submitButtonDisabled}
                                onClick={() => handleSaveOnClick(displayName)}
                            >
                                {"Save"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {/* <Grid container>
                    <Grid item xs={1}>
                        <Typography fontSize={"19px"}>
                            {"Subscription"}
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "50px" }}>

                        </Box>
                    </Grid>
                </Grid> */}
                <Grid container>
                    <Grid item xs={1}>
                        <Typography fontSize={"19px"}>
                            {"Link Account"}
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "50px" }}>
                            {renderLinkAccountComponent("dt")}
                            <Divider />
                            {renderLinkAccountComponent("ad")}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default UserProfile