import { Navigate, Outlet } from "react-router-dom"

type GuardedRouteProps = {
    isLoggedIn: boolean;
}

const GuardedRoute = (props: GuardedRouteProps) => {
    return (
        props.isLoggedIn ? <Outlet/> : <Navigate to="/login" />
    )
}

export default GuardedRoute;