import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit"

export const unauthorizedMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {

        if (isRejectedWithValue(action)) {
            const errorStatus = action.error.status;
            if (errorStatus === 401 && api.getState().auth.isLoggedIn) {
                window.location.href = "/login";
            }
        }

        return next(action);
    }
