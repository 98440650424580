import * as constants from './constants';

export const fileDownload = (data, filename) => {
  const blob = new Blob([data], { type: 'mime' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getSentenceLang = (inputString, threshold) => {
  let chiPattern = /([\u3400-\u4DBF\u4E00-\u9FCC])/gm;
  let chiMatchResult = inputString.match(chiPattern);
  let chiNumber = chiMatchResult === null ? 0 : chiMatchResult.length;

  let words = inputString.split(/\s+/gm);
  var engNumber = words.length;
  for (var i = 0; i < words.length; i++) {
    let word = words[i];
    if (word !== '') {
      if (word.match(chiPattern) !== null) {
        engNumber--;
      }
    } else {
      engNumber--;
    }
  }

  if (chiNumber / (chiNumber + engNumber) > threshold) {
    return constants.LANGUAGE_ZH;
  } else {
    return constants.LANGUAGE_EN;
  }
};

export const noop = () => { }

export const asyncNoop = async () => { }

export const throttle = (callback, wait) => {
  let lastTime = 0;
  return (...args) => {
      const now = Date.now();
      if (now - lastTime >= wait) {
          callback.apply(null, args)
          lastTime = now;
      }
  };
}
