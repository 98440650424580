import * as XLSX from 'xlsx';
import * as utils from "shared/utils";
import { LANGUAGE_EN, LANGUAGE_ZH } from "shared/constants";

export type GlossaryItem = {
    zh: string;
    en: string;
}

export async function loadPairList(file: any, isWrap?: boolean) {
    const fileReader = new FileReader();

    const getFileExtension = () => {
        const fileName = file.name;
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
        return ext.toLowerCase();
    }

    const getGlossaryList = (objs: any, isWrap?: boolean): GlossaryItem[] => {
        let glossaryList;
        const [key1, key2] = Object.keys(objs[0]);
        if (isWrap) {
            glossaryList = objs.map((jsonObject: any) => {
                return {
                    en: typeof jsonObject[key1] === "string" ? jsonObject[key1] : jsonObject[key1].toString(),
                    zh: typeof jsonObject[key2] === "string" ? jsonObject[key2] : jsonObject[key2].toString(),
                }
            });
        } else {
            glossaryList = objs.map((jsonObject: any) => {
                return {
                    zh: typeof jsonObject[key1] === "string" ? jsonObject[key1] : jsonObject[key1].toString(),
                    en: typeof jsonObject[key2] === "string" ? jsonObject[key2] : jsonObject[key2].toString(),
                }
            });
        }

        if (!checkGlossaryLanguages(glossaryList)) {
            //swap language
            glossaryList = glossaryList.map((item: GlossaryItem) => { return { en: item.zh, zh: item.en } })
        }

        const filteredGlossaryList = glossaryList.filter((item: GlossaryItem) => item.zh !== "" && item.en !== "");

        return filteredGlossaryList
    }

    const xlsxFlow = (result: any) => {
        const workbook = XLSX.read(result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        if (workbook.Sheets.hasOwnProperty(sheetName)) {
            const jsonObjects = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                    header: ["key1", "key2"],
                }
            );

            return getGlossaryList(jsonObjects, isWrap)
        } else {
            return [];
        }
    }

    const csvFlow = (result: any) => {
        const splitedData = result.split("\n");
        let resultList = []

        for (let i = 0; i < splitedData.length; i++) {
            let splitSentence = splitedData[i].split(",")
            resultList.push({
                key1: splitSentence[0].trim(),
                key2: splitSentence[1] ? splitSentence[1].trim() : ""
            })
        }
        return getGlossaryList(resultList, false);
    }

    return new Promise<GlossaryItem[]>((resolve, reject) => {
        const fileExtension = getFileExtension()
        fileReader.onload = event => {
            try {
                if (event === null || event.target === null) {
                    reject()
                    return
                }

                const { result } = event.target;
                if (fileExtension === 'xlsx') {
                    resolve(xlsxFlow(result))
                } else if (fileExtension === 'csv') {
                    resolve(csvFlow(result))
                } else {
                    reject(`Unsupported file extension ${fileExtension}`);
                }

            } catch (e) {
                reject(e)
            }
        };

        fileReader.onabort = reject;
        fileReader.onerror = reject;

        if (fileExtension === 'xlsx') {
            fileReader.readAsBinaryString(file);
        } else if (fileExtension === 'csv') {
            fileReader.readAsText(file, 'UTF-8');
        }

    })
}

export function checkGlossaryLanguages(glossaryItems: GlossaryItem[]) {
    const THRESHOLD = 0.5;

    const glossary_ZH = glossaryItems.map((item) => item.zh);
    const zhCount = glossary_ZH.filter((word: any) => utils.getSentenceLang(word, 0.5) === LANGUAGE_ZH).length;
    if (zhCount / glossary_ZH.length < THRESHOLD) {
        return false;
    }

    const glossary_EN = glossaryItems.map((item) => item.en);
    const enCount = glossary_EN.filter((word: any) => utils.getSentenceLang(word, 0.5) === LANGUAGE_EN).length;
    if (enCount / glossary_EN.length < THRESHOLD) {
        return false;
    }

    return true;
}
