import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { throttle } from "../../shared/utils";

type ResponseHeightContextValue = {
    height: number;
    updateHeight: () => void;
}

const ResponsiveHeightContext = createContext<ResponseHeightContextValue | undefined>(undefined);

export const useResponsiveHeight = () => {
    const context = useContext(ResponsiveHeightContext);
    if (!context) {
        throw new Error("You should wrap your component inside <ResponsiveHeightComponent> before calling useLoadingBackdrop()");
    }

    return context;
}

type ResponsiveHeightProps = {
    virtual?: boolean;
}

export const ResponsiveHeight = (props: PropsWithChildren<ResponsiveHeightProps>) => {
    if (!props.virtual) {
        return <ResponsiveHeightComponent {...props} />
    } else {
        return <ResponsiveHeightVirualComponent {...props} />
    }
}

const ResponsiveHeightComponent = (props: PropsWithChildren<any> ) => {
    const parentContext = useContext(ResponsiveHeightContext);
    const [localContext, setLocalContext] = useState({
        height: 0,
        updateHeight: () => { },
    });

    const boxRef = useRef<Element | null>(null);

    const updateHeight = useCallback(() => {
        const box = boxRef.current;
        if (box) {
            requestAnimationFrame(() => {
                setLocalContext({
                    height: box.clientHeight,
                    updateHeight: updateHeight,
                })
            });
        }
    }, []);

    useEffect(() => {
        updateHeight();
        if (!parentContext) {
          const onResize = throttle(updateHeight, 100);
          window.addEventListener("resize", onResize);
          return () => {
            window.removeEventListener("resize", onResize);
          }
        }
      }, [parentContext, updateHeight]);

    return (
        <Box position="relative" width="100%" height="100%">
            {/* https://github.com/mui/material-ui/issues/17010 */}
            <Box {...{ ref: boxRef }} position="absolute" width="100%" height="100%">
                <ResponsiveHeightContext.Provider value={localContext}>
                    {props.children}
                </ResponsiveHeightContext.Provider>
            </Box>
        </Box>
    );
}

const ResponsiveHeightVirualComponent = (props: PropsWithChildren<any>) => {
    const parentContext = useContext(ResponsiveHeightContext);
    const [localContext, setLocalContext] = useState({
        height: 0,
        updateHeight: () => { },
    });

    const updateHeight = useCallback(() => {
        setLocalContext({
            height: 0,
            updateHeight: updateHeight,
        });
    }, []);

    useEffect(() => {
        updateHeight();
        if (parentContext === null) {
            const onResize = () => {
                updateHeight();
            }
    
            window.addEventListener("resize", onResize);
    
            return () => {
                window.removeEventListener("resize", onResize);
            }
        }
    }, [parentContext, updateHeight]);

    return (
        <ResponsiveHeightContext.Provider value={localContext}>
            {props.children}
        </ResponsiveHeightContext.Provider>
    )
}

export const withResponsiveHeight = <T extends any>(InnerComopnent: React.ComponentType<T>): typeof InnerComopnent => (props: any) => {
    return (
        <ResponsiveHeightComponent>
            <InnerComopnent {...props} />
        </ResponsiveHeightComponent>
    )
}

export const withResponsiveHeightVirtual = <T extends any>(InnerComopnent: React.ComponentType<T>): typeof InnerComopnent => (props: any) => {
    return (
        <ResponsiveHeightVirualComponent>
            <InnerComopnent {...props} />
        </ResponsiveHeightVirualComponent>
    )
}
