import { useState } from 'react';
import './App.css';
import { QualityCheck } from './containers/QualityCheck';
import ConfirmDialogProvider from './components/ConfirmDialog/ConfirmDialogProvider';
import { LoadingBackdropProivder } from './components/UI/LoadingBackdrop';
import { Outlet, Route, Routes } from "react-router-dom";
import LoginPage from './containers/LoginPage';
import UserProfile from 'containers/UserProfile';
import TopupPage from 'containers/TopupPage';
import SubscriptionPage from 'containers/SubscriptionPage';
import Appbar from 'components/Appbar';
import { Box } from '@mui/material';
import CreateJobPanel from 'containers/CreateJobPanel';
import SideMenu from 'components/SideMenu';
import GuardedRoute from 'shared/GuardedRoute';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';


function App() {

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggleSideBar: () => void = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  // if (!isLoggedIn) {
  //   return <LoadingMask show={true}/>
  // } else {
  return (
    <div className='container'>
      <LoadingBackdropProivder>
        <ConfirmDialogProvider>
          <ToastContainer />
          <Routes>
            <Route
              path='/'
              element={(
                <>
                  <Appbar toggleSideBar={toggleSideBar} />
                  <Box sx={{ display: "flex" }}>
                    <SideMenu isOpen={isSideBarOpen} toggleSideBar={toggleSideBar} />
                    <Box sx={{ flexGrow: 1, padding: "2.5rem", top: "64px", position: "relative" }}>
                      <Outlet />
                    </Box>
                  </Box>
                </>
              )}
            >
              <Route path='/' element={<CreateJobPanel title='Call Figures' />} />
              <Route path="/glossary_check" element={<CreateJobPanel title='Glossary Check' />} />
              <Route path="/consistency_check" element={<CreateJobPanel title='Consistency Check' />} />
              <Route path="/accuracy_check" element={<CreateJobPanel title='Accuracy Check' />} />
              <Route path='/top-up' element={<TopupPage />} />
              <Route path='/subscription' element={<SubscriptionPage />} />
              <Route element={<GuardedRoute isLoggedIn={isLoggedIn} />}>
                <Route path='/profile' element={<UserProfile />} />
                <Route path='/my-document' element={<QualityCheck />} />
              </Route>
            </Route>
            <Route path='/login' element={<LoginPage />} />
          </Routes>
        </ConfirmDialogProvider>
      </LoadingBackdropProivder>
    </div >
  )
}
// }


export default App;
