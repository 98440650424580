import React, { PropsWithChildren, useEffect, useRef } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
} from "@mui/material";

type ConfirmDialogProps = {
    fullWidth?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"; 
    type?: "normal" | "warning" | "error";
    title?: string;
    content?: React.ReactNode;
    open: boolean;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    onConfirm: React.MouseEventHandler<HTMLButtonElement> & ((event: KeyboardEvent) => void);
    confirmOnEnter?: boolean;
    dialogProps?: Omit<DialogProps, "open" | "onClose" | "fullWidth" | "maxWidth">;
}


export const ConfirmDialog = (props: PropsWithChildren<ConfirmDialogProps>) => {

    const onConfirmRef = useRef(props.onConfirm);
    onConfirmRef.current = props.onConfirm;

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === "Enter") {
            onConfirmRef.current(event);
          }
        };
      
        if (props.open && props.confirmOnEnter) {
          document.addEventListener("keydown", handleKeyDown);
        }
      
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [props.confirmOnEnter, props.open, onConfirmRef]);

    return (
        <Dialog
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth}
            open={props.open}
            onClose={props.onClose}
            {...props.dialogProps}
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.content}
                </DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {"Close"}
                </Button>
                <Button
                    color={props.type === "normal" ? "inherit" : props.type}
                    onClick={props.onConfirm}
                >
                    {"Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;