import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';


const theme = createTheme({
  components: {
    MuiCssBaseline: {
      defaultProps: {

      },
      styleOverrides: {
        body: {
          fontSize: "inherit",
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(0,0,0)',
      // main: '#f7811b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f7811b',
      contrastText: '#ffffff',
    },
    // type: "dark",
  },

});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
