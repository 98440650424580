import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import {noop} from "../../shared/utils";

type ConfirmDialogContextState = {
    fullWidth?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    confirmOnEnter?: boolean;
    type?: "normal" | "warning" | "error";
    title?: string;
    content?: React.ReactNode;
    onConfirm?: (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    onClose?: (event: {} | React.MouseEvent<HTMLButtonElement>, reason?: "backdropClick" | "escapeKeyDown") => void;
}

type OpenConfirmDialogOptions = ConfirmDialogContextState;

type ConfirmDialogContextValue = {
    openConfirmDialog: (options: OpenConfirmDialogOptions) => void;
    closeConfirmDialog: () => void;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextValue | null>(null);

export const ConfirmDialogProvider = (props: PropsWithChildren<{}>) => {

    const [state, setState] = useState<OpenConfirmDialogOptions>({});

    const [isOpen, setIsOpen] = useState(false);

    const { onConfirm, onClose, ...otherProps } = state;

    const handleOnConfirm = (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
        setIsOpen(false);
        onConfirm && onConfirm(event);
    }

    const handleOnClose = (event: {} | React.MouseEvent<HTMLButtonElement>, reason?: "backdropClick" | "escapeKeyDown") => {
        setIsOpen(false);
        onClose && onClose(event, reason);
    }

    const context = useMemo<ConfirmDialogContextValue>(() => ({
        openConfirmDialog: (options) => {
            setIsOpen(true);
            setState(options)
        },
        closeConfirmDialog: () => {
            setIsOpen(false)
        }
    }), []);

    return (
        <ConfirmDialogContext.Provider value={context}>
            {props.children}
            <ConfirmDialog
                open={isOpen}
                onConfirm={handleOnConfirm}
                onClose={handleOnClose}
                dialogProps={{
                    TransitionProps: {
                        onExited: () => {
                            setState({});
                        }
                    }
                }}
                {...otherProps}
            />
        </ConfirmDialogContext.Provider>
    )
}

export const useConfirmDialog = (autoClose = true) => {
    const context = useContext(ConfirmDialogContext);
    if (context === null) {
        throw new Error("You should wrap your component inside <ConfirmDialogProvider> before calling useConfirmDialog()");
    }

    useEffect(() => {
        return () => {
            if (autoClose) {
                context.closeConfirmDialog();
            }
        }
    }, [autoClose, context]);

    return context;
}

export default ConfirmDialogProvider;