import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

export type QueryArgs = {
    url: string;
    config?: AxiosRequestConfig;
}

axios.defaults.baseURL = '/api'

const axiosBaseQuery = (): BaseQueryFn<QueryArgs> => async (args, api, extraOptions) => {
    try {
        const {
            url,
            config = {},
        } = args;

        if (!config.method) {
            config.method = 'GET';
        }
        const response = await axios.request({ url, ...config});

        return {
            data: response.data,
        };

    } catch (axiosError) {
        const error = (axiosError as AxiosError);

        return {
            error: {
                status: error.response?.status,
                data: error.response?.data,
                msg: error.message
            }
        };
    }
}

export const baseApi = createApi({
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        "quality-check/create-job",
        "quality-check/list",
        "quality-check/rename",
        "quality-check/delete",
        "user-profile",
        'quality-check/my-glossary/list'
    ],
    endpoints: () => ({}),
});

export default baseApi;