import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import stripePaymentApi from "store/apis/stripePaymentApi";
import { RootState } from "store/store";
import { StripePaymentSubscriptionPlan } from "types/models/StripePaymentSubscriptionPlan";
import { useSelector } from "react-redux";

const SubscriptionPage = () => {

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    const navigate = useNavigate();
    const { data: subscriptionPlans, isSuccess: subscriptionPlanOk } = stripePaymentApi.useGetSubscriptionPlansQuery();
    const [length, setLength] = useState(0);

    useEffect(() => {
        setLength(subscriptionPlans?.length || 0);
    }, [subscriptionPlans])

    const renderSubscriptionPlan = (product: string, productId: number, index: number, length: number) => {
        const url = `/api/stripe-payment/subscription/create-checkout-session`
        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "row", margin: "20px", alignItems: "center" }} key={index}>
                    <Grid item xs={2}>
                        <Typography>
                            {product}
                        </Typography>
                    </Grid>
                    <Grid xs={9} />
                    <Grid xs={1}>
                        <form action={url} method="POST">
                            <input type="hidden" name="subscriptionPlanId" value={productId} />
                            <input type="hidden" name="quantity" value="1" />
                            <Button type="submit" onClick={()=>{if (!isLoggedIn) {navigate("/login")}}}>
                                purchase
                            </Button>
                        </form>
                    </Grid>
                </Box>
                {index !== length - 1 && <Divider />}
            </>
        )
    }

    if (!subscriptionPlanOk) return (<div>loading...</div>)
    else
        return (
            <>
                <Box sx={{ height: "25px", display: "flex", alignItems: "end" }}>
                    <Typography fontSize={25}>
                        {"Subscription"}
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-start", maxWidth: "12%" }}>
                            <Typography fontSize={"19px"}>
                                {"Plan"}
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "50px" }}>
                                {
                                    subscriptionPlans?.map((subscriptionPlan: StripePaymentSubscriptionPlan, index: number) => (
                                        renderSubscriptionPlan(subscriptionPlan.subscriptionPlanName, subscriptionPlan.subscriptionPlanId, index, length)
                                    ))
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
}

export default SubscriptionPage;