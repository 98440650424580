import { configureStore } from "@reduxjs/toolkit";
import baseApi from "./apis/baseApi";
import authReducer from "../features/authSlice";
import { unauthorizedMiddleware } from "./unauthorizedMiddleware";

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware();
        return middleware.concat(baseApi.middleware, unauthorizedMiddleware);
    }
})

export type RootState = ReturnType<typeof store.getState>;

export default store;