
type SimplePrimitive = boolean | number | string | null | undefined;

type SimpleMap = {
    [key: string]: SimplePrimitive | SimplePrimitive[];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Build a URLSearchParams object with provided data * 
 * Values of null or undefined are excluded          *
 * @param data                                       *
 * @returns                                          *
 * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const buildUrlSearchParams = (data: SimpleMap) => {

    const urlSearchParams = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
        if (value instanceof Array) {
            for (const item of value) {
                if (item !== null && item !== undefined) {
                    if (typeof item === 'number' || typeof item === 'boolean') {
                        urlSearchParams.append(key, item.toString());
                    } else {
                        urlSearchParams.append(key, item);
                    }
                }
            }
        } else {
            if (value !== null && value !== undefined) {
                if (typeof value === 'number' || typeof value === 'boolean') {
                    urlSearchParams.append(key, value.toString());
                } else {
                    urlSearchParams.append(key, value);
                }
            }
        }
    })

    return urlSearchParams;
}

type SimpleFormDataPrimitive = boolean | number | string | File | null | undefined;

type SimpleFormDataMap = {
    [key: string]: SimpleFormDataPrimitive | SimpleFormDataPrimitive[];
}

export const buildFormData = (data: SimpleFormDataMap) => {

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (value instanceof Array) {
            for (const item of value) {
                if (item !== null && item !== undefined) {
                    if (typeof item === 'number' || typeof item === 'boolean') {
                        formData.append(key, item.toString());
                    } else {
                        formData.append(key, item);
                    }
                }
            }
        } else {
            if (value !== null && value !== undefined) {
                if (typeof value === 'number' || typeof value === 'boolean') {
                    formData.append(key, value.toString());
                } else {
                    formData.append(key, value);
                }
            }
        }
    })

    return formData;
}