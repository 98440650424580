import { baseApi } from "./baseApi";
import { QualityCheckJobList, QualityCheckJobUserProfile } from "../../types";
import { buildFormData, buildUrlSearchParams } from "./helper";
import { qualityCheckMyGlossary } from "types/models/QualityCheckMyGlossary";
import { qualityCheckCreateJobResponse } from "types/models/QualityCheckCreateJobResponse";

export type QualityCheckJobListQueryArgs = {
    userId: number;
}

export type GlossaryItem = {
    en: string;
    zh: string;
}

export type QualityCheckCreateJobMutationArgs = {
    jobInfo: Blob
    sourceFile: File;
    targetFile: File;
}

export type QualityCheckJobRenameMutationArgs = {
    jobId: number;
    name: string;
}

export type QualityCheckJobDeleteMutationArgs = {
    jobId: number;
}

export type QaulityCheckJobResultQueryArgs = {
    jobId: number;
}

export type QualityCheckUserRenameArgs = {
    name: string;
}

export type QualityCheckOAuth2UnlinkArgs = {
    oAuth2Provider: string;
}

export type QualityCheckMyGlossaryDeleteMutationArgs = {
    myGlossaryId: number;
}

export type QualityCheckMyGlossaryImportMutationArgs = {
    glossaryName: string,
    glossaryItems: GlossaryItem[]
}

export type QualityCheckMyGlossaryGlossaryModifyMutationArgs = {
    myGlossaryId: number,
    editedName: string,
    editedGlossary: {
        glossaryId: number,
        en: string,
        zh: string
    }[],
    addedGlossary: GlossaryItem[],
    deletedGlossary: number[]
}

export type QualityCheckGlossaryExtractMutationArgs = {
    enP: string,
    zhP: string
}

export type QualityCheckGLossaryExtractionDocMutationArgs = {
    enFile: File,
    zhFile: File
}

export type QualityCheckInitialCheckingMutationArgs = {
    sourceFile: File,
    targetFile: File
}

export type QualityCheckInitialCheckingResponse = {
    sourceWordCount: number,
    targetWordCount: number
}

export type QualityCheckGlossaryExtractResultArgs = {
    uid: string
}

export type QualityCheckGlossaryExtractResponse = {
    jobId: number
}

const qualityCheckApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        qualityCheckJobList: build.query<QualityCheckJobList, void>({
            query: () => {
                const url = "quality-check/list";
                return {
                    url: url,
                }
            },
            providesTags: (result) => {
                const idTags = result?.map((item) => ({
                    type: "quality-check/list" as const,
                    id: item.jobId,
                })) ?? [];
                return ['quality-check/list', ...idTags];
            },
        }),
        qualityCheckCreateJob: build.mutation<qualityCheckCreateJobResponse, QualityCheckCreateJobMutationArgs>({
            query: (args) => {
                const url = "quality-check/create-job";
                return {
                    url,
                    config: {
                        method: 'POST',
                        data: args,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                }
            },
        }),
        qualityCheckJobRename: build.mutation<void, QualityCheckJobRenameMutationArgs>({
            query: (args) => {
                const url = "quality-check/rename";
                const formData = buildFormData(args);
                return {
                    url,
                    config: {
                        method: 'PUT',
                        data: formData,
                    },
                }
            },
            invalidatesTags: (response, error, args) => ([
                "quality-check/list",
                {
                    type: "quality-check/list",
                    id: args.jobId,
                }
            ])

        }),
        qualityCheckJobDelete: build.mutation<void, QualityCheckJobDeleteMutationArgs>({
            query: (args) => {
                const url = "quality-check/delete";
                const formData = buildUrlSearchParams(args);
                return {
                    url,
                    config: {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        data: formData,
                    },
                }
            },
            invalidatesTags: (response, error, args) => ([
                "quality-check/list",
                {
                    type: "quality-check/list",
                    id: args.jobId,
                }
            ])

        }),
        qualityCheckJobResult: build.query<Blob, QaulityCheckJobResultQueryArgs>({
            query: (args) => {
                const url = "qualit-check/" + args.jobId + "/result/export";
                return {
                    url,
                    config: {
                        method: 'GET',
                    },
                }
            }
        }),
        qualityCheckJobUserProfile: build.query<QualityCheckJobUserProfile, void>({
            query: () => {
                const url = "quality-check/user-info";
                return {
                    url,
                    config: {
                        method: 'GET',
                    },
                }
            },
            providesTags: ["user-profile"],
        }),
        qualityCheckUserRename: build.mutation<void, QualityCheckUserRenameArgs>({
            query: (args) => {
                const url = "quality-check/user-rename";
                const formData = buildFormData(args);
                return {
                    url,
                    config: {
                        method: "PUT",
                        data: formData,
                    },
                }
            },
            invalidatesTags: ["user-profile"],
        }),
        qualityCheckOAuth2Unlink: build.mutation<void, QualityCheckOAuth2UnlinkArgs>({
            query: (args) => {
                const url = "quality-check/unlink";
                const formData = buildFormData(args);
                return {
                    url,
                    config: {
                        method: "PUT",
                        data: formData,
                    },
                }
            },
            invalidatesTags: ["user-profile"],
        }),
        qualityCheckMyGlossaryList: build.query<qualityCheckMyGlossary[], void>({
            query: () => {
                const url = "quality-check/my-glossary/list";
                return {
                    url,
                    config: {
                        method: "GET",
                    },
                }
            },
            providesTags: (result) => {
                const idTags = result?.map((item) => ({
                    type: "quality-check/my-glossary/list" as const,
                    id: item.myGlossaryId,
                })) ?? [];
                return ['quality-check/my-glossary/list', ...idTags];
            },
        }),
        qualityCheckMyGlossaryDelete: build.mutation<void, QualityCheckMyGlossaryDeleteMutationArgs>({
            query: (args) => {
                const url = "quality-check/my-glossary/delete";
                const formData = buildUrlSearchParams(args);
                return {
                    url,
                    config: {
                        method: "DELETE",
                        data: formData,
                    }
                }
            },
            invalidatesTags: (response, error, args) => ([
                "quality-check/my-glossary/list",
                {
                    type: "quality-check/my-glossary/list",
                    id: args.myGlossaryId,
                }
            ])
        }),
        qualityCheckMyGlossaarImport: build.mutation<void, QualityCheckMyGlossaryImportMutationArgs>({
            query: (args) => {
                const url = "quality-check/my-glossary/import";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: JSON.stringify(args),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                }
            },
            invalidatesTags: (response, error, args) => (["quality-check/my-glossary/list"])
        }),
        qualityCheckMyGlossaryGlossaryModify: build.mutation<void, QualityCheckMyGlossaryGlossaryModifyMutationArgs>({
            query: (args) => {
                const url = "quality-check/my-glossary/glossary/modify";
                return {
                    url,
                    config: {
                        method: "PUT",
                        data: JSON.stringify(args),
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                }
            },
            invalidatesTags: (response, error, args) => ([
                "quality-check/my-glossary/list",
                {
                    type: "quality-check/my-glossary/list",
                    id: args.myGlossaryId,
                }
            ])
        }),
        qualityCheckGlossaryExtract: build.mutation<QualityCheckGlossaryExtractResponse, QualityCheckGlossaryExtractMutationArgs>({
            query: (args) => {
                const url = "quality-check/extract-glossary";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: JSON.stringify(args),
                        headers: {
                            "Content-Type": "application/json",
                        }
                    }
                }
            },
            invalidatesTags: (response, error, args) => (["user-profile"])
        }),
        qualityCheckGlossaryExtractDoc: build.mutation<QualityCheckGlossaryExtractResponse, QualityCheckGLossaryExtractionDocMutationArgs>({
            query: (args) => {
                const url = "quality-check/extract-glossary/doc";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: args,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                }
            },
            invalidatesTags: (response, error, args) => (["user-profile"])
        }),
        qualityCheckInitialChecking: build.mutation<QualityCheckInitialCheckingResponse, QualityCheckInitialCheckingMutationArgs>({
            query: (args) => {
                const url = "quality-check/initial-checking";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: args,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                }
            }
        }),
        qualityCheckGlossaryExtractResult: build.mutation<GlossaryItem[], QualityCheckGlossaryExtractResultArgs>({
            query: (args) => {
                const url = "quality-check/extract-glossary/" + args.uid + "/result";
                return {
                    url,
                    config: {
                        method: "GET",
                    }
                }
            }
        })
    }),
    overrideExisting: false,
});

export default qualityCheckApi;