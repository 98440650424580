import { Box, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Refresh, Sync, SyncDisabled } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { asyncNoop, noop } from "../shared/utils";

const getTablePreferenceKey = (tableId: string) => {
    return `${tableId}-prefrence`;
}

const getTablePreference = (tablePreferenceKey: string): Record<string, any> => {
    try {
        const localStorageValue = localStorage.getItem(tablePreferenceKey);
        if (localStorageValue != null) {
            const tablePreference = JSON.parse(localStorageValue);

            if (tablePreference instanceof Object && !(tablePreference instanceof Array)) {
                return tablePreference;
            }
        }
        return {}
    } catch (error) {
        return {}
    }
}

const setTablePreference = (tablePreferenceKey: string, key: string, value: any) => {
    const tablePreference = getTablePreference(tablePreferenceKey);
    tablePreference[key] = value;
    localStorage.setItem(tablePreferenceKey, JSON.stringify(tablePreference));
}

const AUTO_REFRESH = "autoRefresh";

type AutoRefreshOptions = {
    enabled: boolean;
    tableId: string;
    onRefresh: typeof noop | typeof asyncNoop;
    refreshInterval: number;
}

export const useAutoRefresh = (options: AutoRefreshOptions) => {

    const {
        enabled,
        tableId,
        onRefresh,
        refreshInterval,
    } = options;

    const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

    const tablePreferenceKey = getTablePreferenceKey(tableId);
    const tablePreference = getTablePreference(tablePreferenceKey);
    const autoRefreshPreference = tablePreference[AUTO_REFRESH];
    
    const [isAutoRefresh, setIsAutoRefresh] = useState(!!autoRefreshPreference);

    const handleAutoRefreshOnChange = useCallback((checked: boolean) => {
        setTablePreference(tablePreferenceKey, AUTO_REFRESH, checked);
        setIsAutoRefresh(checked);
    }, [tablePreferenceKey]);

    useEffect(() => {
        const timeout = timeoutRef.current;
        clearTimeout(timeout);
        timeoutRef.current = undefined;

        if (enabled) {
            if (isAutoRefresh) {
                const setRefreshTimeout = () => {
                    timeoutRef.current = setTimeout(async () => {
                        await onRefresh();
                        setRefreshTimeout();
                    }, refreshInterval);
                }

                setRefreshTimeout();
            }
        }

        return () => {
            const timeout = timeoutRef.current;
            clearTimeout(timeout);
            timeoutRef.current = undefined;
        }
    }, [enabled, isAutoRefresh, onRefresh, refreshInterval]);

    return {
        isAutoRefresh: isAutoRefresh,
        setIsAutoRefresh: handleAutoRefreshOnChange,
    }
}

const useStyles = makeStyles({
    "sync": {
        transform: "rotate(45deg)",
    },
});

type RefreshToolProps = {
    autoRefreshDisabled?: boolean;
    tableId: string;
    refreshInterval: number;
    onRefresh: typeof noop | typeof asyncNoop;
    lastUpdateTime?: Date;
}

export const RefreshTool = (props: RefreshToolProps) => {
    const { 
        autoRefreshDisabled = false,
        tableId, 
        refreshInterval, 
        onRefresh, 
        lastUpdateTime
    } = props;

    const { isAutoRefresh, setIsAutoRefresh } = useAutoRefresh({
        enabled: !autoRefreshDisabled,
        tableId,
        refreshInterval,
        onRefresh
    });

    const handleRefreshOnClick = () => {

        onRefresh();
    }

    const renderAutoRefreshTooptip = () => {
        return (
            <Box width="200px">
                <Typography variant="subtitle1">
                    {"Auto-Refresh is "}{isAutoRefresh ? "enabled" : "disabled"}
                </Typography>
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">
                        {"Auto-Refresh"}
                    </Typography>
                    <Box flexGrow={1} />
                    <Switch
                        size="small"
                        color="secondary"
                        checked={isAutoRefresh}
                        onChange={(event, checked) => setIsAutoRefresh(checked)}
                    />
                </Box>
            </Box>
        )
    }
    
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Tooltip title={"Refresh now"}>
                <IconButton onClick={handleRefreshOnClick} size="large">
                    <Refresh />
                </IconButton>
            </Tooltip>
            {!autoRefreshDisabled && (
                <Tooltip
                    arrow
                    leaveDelay={200}
                    title={renderAutoRefreshTooptip()}
                >
                    <IconButton size="large">
                        {isAutoRefresh ? <Sync className={classes["sync"]} /> : <SyncDisabled />}
                    </IconButton>
                </Tooltip>
            )}
            <Box mx={2}>
                <Typography variant="body1">
                    {"Last refresh time: "} <br />
                    {lastUpdateTime && lastUpdateTime.toLocaleString()}
                </Typography>
            </Box>
            
        </Box>
    );
}

export default RefreshTool;