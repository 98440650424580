import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, LinearProgressProps, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import QualityCheckFileUploadPanel from "../components/QualityCheckFileUploadPanel";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { loadPairList } from "shared/glossaryutils";
import qualityCheckApi from "store/apis/qualityCheckApi";
import axios, { AxiosError } from "axios";
import { mainFunctionEnumSettings } from "../components/SideMenu";
import { Client, IMessage } from "@stomp/stompjs";
import { fileDownload } from "shared/utils";
import LoadingButton from '@mui/lab/LoadingButton';
import { qualityCheckMyGlossary } from "types/models/QualityCheckMyGlossary";
import { JobStatusUpdateMessage } from "types/models/JobStatusUpdateMessage";
import { JobStatus } from "../types/models/JobStatus";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { stompClient } from "shared/StompClient";

type createJobPanelProps = {
    title: string;
}

const CreateJobPanel = (props: createJobPanelProps) => {

    const { title } = props;

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    const { refetch: updateCredit, data: user } = qualityCheckApi.useQualityCheckJobUserProfileQuery();
    const { data, refetch } = qualityCheckApi.useQualityCheckMyGlossaryListQuery();
    const [createJob] = qualityCheckApi.useQualityCheckCreateJobMutation();
    const [initialChecking, { data: wordCount, isSuccess: isInitialCheckingSuccess, isLoading: isInitialChecking }] = qualityCheckApi.useQualityCheckInitialCheckingMutation();

    const [isSaveToMyGlossary, setIsSaveToMyGlossary] = useState(false);
    const [jobId, setJobId] = useState(-1);
    const [isReadyToDownload, setIsReadyToDownload] = useState(false);
    const [creditUsage, setCreditUsage] = useState<number>(-1);
    const [isValidGlossary, setIsValidGlossary] = useState(true);
    const [isValidForm, setIsValidForm] = useState(false);
    const [status, setStatus] = useState("Pending...");
    const [progress, setProgress] = useState(0);
    const [connected, setConnected] = useState(false);

    const isButtonDisabled = user?.subscriptionStatus === "ACTIVE" ? !isValidForm || !isValidGlossary : !isValidForm || !isValidGlossary || !isInitialCheckingSuccess;

    const [args, setArgs] = useState({
        jobInfo: {
            name: "",
            sourceDoc: [] as File[],
            targetDoc: [] as File[],
        },
        checkingMode: "",
        glossary: {
            doc: [] as File[],
            name: "",
            id: -1,
        }
    });

    useEffect(() => {
        // validate glossary
        if (title === "Glossary Check") {
            if (isLoggedIn) {
                if (args.glossary.doc.length !== 0) {
                    if (isSaveToMyGlossary) {
                        setIsValidGlossary((args.glossary.name.length !== 0 && data?.find(glossary => glossary.glossaryName === args.glossary.name) === undefined))
                    } else {
                        setIsValidGlossary(true)
                    }
                } else if (args.glossary.id !== -1) {
                    setIsSaveToMyGlossary(false)
                    setIsValidGlossary(true)
                } else {
                    setIsValidGlossary(false)
                }
            } else {
                if (args.glossary.doc.length !== 0) {
                    setIsValidGlossary(true)
                } else {
                    setIsValidGlossary(false)
                }
            }
        }
    }, [args.glossary, data, isLoggedIn, isSaveToMyGlossary])

    useEffect(() => {
        //set glossary name when glossary file uploaded
        setArgs(prevState => ({
            ...prevState,
            glossary: {
                ...prevState.glossary,
                name: args.glossary.doc.length === 0 ? "" : args.glossary.doc[0].name
            }
        }))
        // reset save to my glossary option state
        if (args.glossary.doc.length === 0) {
            setIsSaveToMyGlossary(false)
        }
    }, [args.glossary.doc])

    useEffect(() => {
        // load credit usage data to display when initial checking is success
        if (wordCount !== undefined && isInitialCheckingSuccess) {
            setCreditUsage(wordCount.sourceWordCount + wordCount.targetWordCount)
        }
    }, [isInitialCheckingSuccess])

    useEffect(() => {
        //validate form
        if (isLoggedIn) {
            if (args.jobInfo.sourceDoc.length === 0 || args.jobInfo.targetDoc.length === 0 || args.jobInfo.name === "") {
                setIsValidForm(false)
            } else {
                setIsValidForm(true)
            }
        } else {
            if (args.jobInfo.sourceDoc.length === 0 || args.jobInfo.targetDoc.length === 0) {
                setIsValidForm(false)
            } else {
                setIsValidForm(true)
            }
        }
    }, [args.jobInfo])

    useEffect(() => {
        // request to calculate credit usage when source and target file is uploaded
        setCreditUsage(-1)
        setJobId(-1)
        if (args.jobInfo.sourceDoc.length > 0 && args.jobInfo.targetDoc.length > 0) {
            initialChecking({ sourceFile: args.jobInfo.sourceDoc[0], targetFile: args.jobInfo.targetDoc[0] })
        }
    }, [args.jobInfo.sourceDoc, args.jobInfo.targetDoc])

    useEffect(() => {
        setJobId(-1);
        disconnect();
        //reset state
        setArgs({
            jobInfo: {
                name: title,
                sourceDoc: [] as File[],
                targetDoc: [] as File[],
            },
            checkingMode: mainFunctionEnumSettings[title].mode,
            glossary: {
                doc: [] as File[],
                name: "",
                id: -1,
            }
        })
        setIsReadyToDownload(false);
        setIsValidGlossary(title === "Glossary Check" ? false : true)
    }, [title])

    useEffect(() => {
        setArgs((prevState) => ({
            ...prevState,
            glossary: {
                ...prevState.glossary,
                name: args.glossary.doc.length === 0 ? "" : args.glossary.doc[0].name
            }
        }));
    }, [isSaveToMyGlossary]);

    useEffect(() => {
        if (jobId !== -1) {
            stompClient.subscribe("/queue/job-result/" + jobId, async (msg: IMessage) => {
                const message: JobStatusUpdateMessage = JSON.parse(msg.body);
                setStatus(message.status)
                if (message.status.includes("ERROR")) {
                    disconnect();
                    toast.error(message.status)
                    return
                }
                setProgress((prevProgress) => prevProgress + 25);
                if (message.status === JobStatus.COMPLETE) {
                    disconnect();
                    setIsReadyToDownload(true);
                    toast.success("Quality check job is completed")
                }
            })
        }

    }, [jobId])

    const disconnect = () => {
        stompClient.deactivate();
        setConnected(false);
        updateCredit();
    }

    const createUploadHandler = (type: string) => (file: File[]) => {

        if (type === "source") {
            setArgs(prevState => ({
                ...prevState,
                jobInfo: {
                    ...prevState.jobInfo,
                    sourceDoc: file,
                }
            }))
        }

        if (type === "target") {
            setArgs(prevState => ({
                ...prevState,
                jobInfo: {
                    ...prevState.jobInfo,
                    targetDoc: file,
                }
            }))
        }

        if (type === "glossary") {
            setArgs(prevState => ({
                ...prevState,
                glossary: {
                    ...prevState.glossary,
                    doc: file,
                }
            }))
        }
        stompClient.deactivate();
    };

    const handleDownloadResult = async () => {
        const fileName = args.jobInfo.name + "_RESULT.zip"
        const resultFile = await axios.get(`quality-check/${jobId}/result/export`, {
            responseType: 'blob',
        });
        fileDownload(resultFile.data, fileName)
    }

    const handleSubmitQualityCheck = async () => {
        setProgress(0)
        setStatus("Pending...")
        if (isLoggedIn) {
            if (user!.subscriptionStatus === "INACTIVE" && user!.creditBalance < creditUsage) {
                toast.error("Insufficient credit balance, please top-up or subscribe Addcuracy+ to continue!");
                return;
            }
        } else {
            window.location.href = "/login"
            return;
        }

        const id = toast.loading("Quality check job submit is pending")
        const glossaryList = args.glossary.doc.length === 0 ? undefined : await loadPairList(args.glossary.doc[0])
        const jobInfoJson = JSON.stringify({
            jobName: args.jobInfo.name,
            checkingMode: args.checkingMode,
            glossaryItems: glossaryList,
            isSaveGlossary: isSaveToMyGlossary,
            myGlossaryId: args.glossary.id === -1 ? undefined : args.glossary.id,
            glossaryName: args.glossary.name
        })
        const jobInfoBlob = new Blob([jobInfoJson], { type: "application/json" });
        stompClient.activate();
        setConnected(true);

        try {
            await createJob({ jobInfo: jobInfoBlob, sourceFile: args.jobInfo.sourceDoc[0], targetFile: args.jobInfo.targetDoc[0] }).unwrap().then((data) => {
                toast.update(id, { render: "Quality check job submit is successed", type: "success", isLoading: false, autoClose: 2000 });
                setJobId(data.jobId);
                updateCredit();
            }).catch((error) => {
                toast.update(id, { render: error.message, type: "error", isLoading: false, autoClose: 2000 });
            });

            setArgs(prevState => ({
                ...prevState,
                sourceDoc: [] as File[],
                targetDoc: [] as File[],
                glossaryDoc: [] as File[],
                jobName: title,
                glossaryName: "",
                glossaryId: -1,
            }));
            setIsSaveToMyGlossary(false);
            setIsReadyToDownload(false);
            refetch();
        } catch (error) {
            toast.update(id, { render: "update information fail", type: "error", isLoading: false, autoClose: 2000 });
        }
    };

    const renderGlossaryHandler = () => {
        return (
            <>
                <Grid item xs={12}>
                    <QualityCheckFileUploadPanel
                        title="Upload glossary file"
                        document={args.glossary.doc}
                        onDrop={createUploadHandler('glossary')}
                        disableGlossary={args.glossary.id !== -1}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    {isLoggedIn &&
                        <FormControlLabel
                            control={<Checkbox disabled={args.glossary.doc.length === 0} sx={{ "&.Mui-checked": { color: "black" } }} />}
                            label="Save to my glossary"
                            checked={isSaveToMyGlossary}
                            onChange={() => { setIsSaveToMyGlossary(!isSaveToMyGlossary) }}
                        />
                    }
                    {isSaveToMyGlossary &&
                        <TextField
                            sx={{
                                borderColor: "black",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "black"
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black'
                                    }
                                },
                            }}
                            label={
                                <span color="black">
                                    {"Glossary Name"}
                                </span>
                            }
                            variant="outlined"
                            value={args.glossary.name}
                            onChange={(event) => { setArgs(prevState => ({ ...prevState, glossary: { ...prevState.glossary, name: event.target.value } })) }}
                            error={!isValidGlossary}
                            helperText={args.glossary.name.length === 0 ? "Glossary name cannot be empty" : data?.find(glossary => glossary.glossaryName === args.glossary.name) !== undefined ? "Glossary name already exist" : ""}
                        />
                    }
                </Grid>
                {
                    isLoggedIn &&
                    <Grid container>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} style={{ paddingTop: "0px" }}>
                            <Typography>
                                or
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} style={{ paddingTop: "15px" }}>
                            <Typography variant="subtitle1">
                                Select from:
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel>My Glossary</InputLabel>
                                <Select
                                    onChange={(event: SelectChangeEvent<typeof args.glossary.id>) => { setArgs((prevState) => ({ ...prevState, glossary: { ...prevState.glossary, id: Number(event.target.value) } })); }}
                                    autoWidth
                                    label="My Glossary"
                                    variant="outlined"
                                    disabled={args.glossary.doc.length !== 0}
                                    value={args.glossary.id}
                                    MenuProps={{
                                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        None
                                    </MenuItem>
                                    {data?.map((glossary: qualityCheckMyGlossary) => (
                                        <MenuItem value={glossary.myGlossaryId}>
                                            {glossary.glossaryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const LinearProgressWithLable = (props: LinearProgressProps & { value: number }) => {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {mainFunctionEnumSettings[title].functionality}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <QualityCheckFileUploadPanel
                    title="Upload source file"
                    document={args.jobInfo.sourceDoc}
                    onDrop={createUploadHandler('source')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <QualityCheckFileUploadPanel
                    title="Upload target file"
                    document={args.jobInfo.targetDoc}
                    onDrop={createUploadHandler('target')}
                />
            </Grid>
            {title === "Glossary Check" && renderGlossaryHandler()}
            {
                isLoggedIn &&
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={
                            <span color="black">
                                Job Name
                            </span>
                        }
                        size="medium"
                        variant="outlined"
                        value={args.jobInfo.name}
                        onChange={(event) => { setArgs(prevState => ({ ...prevState, jobInfo: { ...prevState.jobInfo, name: event.target.value } })) }}
                        sx={{
                            borderColor: "black",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "black"
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black'
                                }
                            },

                        }}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <Typography>
                    Credit Usage: {creditUsage !== -1 && creditUsage} {isInitialChecking && "calculating..."}
                </Typography>
                {/* {!isLoggedIn && creditUsage > 5000 &&
                        <Box sx={{ display: "flex" }}>
                            <ErrorOutlineIcon sx={{ color: "red" }} />
                            <Typography color="error">
                                {"Trial user are only allow to check 5000 words"}
                            </Typography>
                        </Box>
                    } */}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                    <LoadingButton
                        sx={{ color: "black" }}
                        onClick={handleSubmitQualityCheck}
                        disabled={isButtonDisabled}
                        loading={connected}
                    >
                        {"Start"}
                    </LoadingButton>
                    {isReadyToDownload &&
                        <Button onClick={handleDownloadResult}>
                            {"Download"}
                        </Button>
                    }
                </Box>
                {jobId !== -1 &&
                    <Box sx={{ width: '100%' }}>
                        <Typography color={"gray"}>{status}</Typography>
                        <LinearProgressWithLable value={progress} />
                    </Box>
                }
            </Grid>
        </Grid >
    )
}

export default CreateJobPanel;