import { StripePaymentProduct } from "types/models/StripePaymentProduct";
import baseApi from "./baseApi";
import { StripePaymentSubscriptionPlan } from "types/models/StripePaymentSubscriptionPlan";

const stripePaymentApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProducts: build.query<StripePaymentProduct[], void>({
            query: () => {
                const url = "stripe-payment/products";
                return {
                    url,
                    config: {
                        method: "GET",
                    }
                }
            }
        }),
        getSubscriptionPlans: build.query<StripePaymentSubscriptionPlan[], void>({
            query: () => {
                const url = "stripe-payment/subscriptions-plans";
                return {
                    url,
                    config: {
                        method: "GET",
                    }
                }
            }
        })
    })
});

export default stripePaymentApi;