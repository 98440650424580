import { Button, CSSObject, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Slide, Theme, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import { TbTargetArrow } from 'react-icons/tb';
import { BiBook } from 'react-icons/bi'
import { useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useEffect, useState, useRef } from "react";
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import qualityCheckApi from "store/apis/qualityCheckApi";
import ConstructionIcon from '@mui/icons-material/Construction';
import ClearIcon from '@mui/icons-material/Clear';
import { qualityCheckMyGlossary } from "types/models/QualityCheckMyGlossary";
import { FiUpload } from 'react-icons/fi'
import { GlossaryItem, loadPairList } from "shared/glossaryutils";
import EditGlossaryPanel from "./EditGlossaryPanel";
import CreateGlossaryPanel from "./CreateGlossaryPanel";
import BuildGlossaryPanel from "../containers/BuildGlossaryPanel";
import { TransitionGroup } from 'react-transition-group';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { RootState } from "store/store";
import { useSelector } from "react-redux";

type SideMenuProps = {
    isOpen: boolean;
    toggleSideBar: () => void;
}

const paymentPageSetting: Record<string, MainFunctionEnumSetting> = {
    "Addcuracy Plus+": {
        icon: <DiamondOutlinedIcon style={{ height: "24px" }} />,
        link: "/subscription",
        mode: "",
        functionality: ""
    },
    "Top Up": {
        icon: <CreditCardIcon style={{ height: "24px", width: "24px" }} />,
        link: "/top-up",
        mode: "",
        functionality: ""
    }
}

export type MainFunctionEnum = "Call Figures" | "Glossary Check" | "Consistency Check" | "Accuracy Check";

type MainFunctionEnumSetting = {
    icon: ReactElement;
    mode: string;
    link: string;
    functionality: string;
}

export const mainFunctionEnumSettings: Record<string, MainFunctionEnumSetting> = {
    "Call Figures": {
        icon: <CalculateOutlinedIcon />,
        mode: "CALLFIGURE",
        link: '/',
        functionality: "Call Figures tries to match up figures (i.e. numbers, dates and times) in FILE 1 (Source) with those in FILE 2 (Translation)."
    },
    "Glossary Check": {
        icon: <BiBook style={{ height: "24px", width: "24px" }} />,
        mode: "GLOSSARY_CHECKING",
        link: "/glossary_check",
        functionality: "Glossary Check tries to match up user defined glossary in FILE 1 (Source) with those in FILE 2 (Translation). it detect instances where glossary terms were translated in a manner inconsistent with the glossary."
    },
    "Consistency Check": {
        icon: <RuleIcon />,
        mode: "CONSISTENCY_CHECKING",
        link: "/consistency_check",
        functionality: "Consistency Check tries to detect terms that were translated in more than one way."
    },
    "Accuracy Check": {
        icon: <TbTargetArrow style={{ height: "24px", width: "24px" }} />,
        mode: "ACCURACY_CHECKING",
        link: "/accuracy_check",
        functionality: "Accuracy Check detects sentences for which there is possible under-translation or over-translation."
    }
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: 265,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: 265,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const SideMenu = (props: SideMenuProps) => {

    const navigate = useNavigate();

    const location = useLocation();

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    const [isExpand, setIsExpand] = useState(false);
    const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);
    const [isOpenEditGlossary, setIsOpenEditGlossary] = useState(false);
    const [isOpenCreateGlossary, setIsOpenCreateGlossary] = useState(false);
    const [isOpenBuildGlossaryPanel, setIsOpenBuildGlossaryPanel] = useState(false);
    const [selectedGlossaryId, setSelectedGlossaryId] = useState(-1);
    const [glossaryList, setGlossaryList] = useState<GlossaryItem[]>([]);
    const [glossaryName, setGlossaryName] = useState<string>("");
    const [selectedGlossaryItem, setSelectedGlossaryItem] = useState<qualityCheckMyGlossary>({ myGlossaryId: -1, glossaryName: "", glossaryItems: [] });

    const fileUploadFieldRef = useRef<HTMLInputElement>(null);

    const { data } = qualityCheckApi.useQualityCheckMyGlossaryListQuery();
    const [deleteMyGlossary] = qualityCheckApi.useQualityCheckMyGlossaryDeleteMutation();

    useEffect(() => {
        if (!isLoggedIn || !props.isOpen) {
            setIsExpand(false);
        }
    }, [isLoggedIn, props.isOpen])

    const handleExpandMyGlossary = () => {
        if (!props.isOpen) {
            props.toggleSideBar();
        }
        setIsExpand(!isExpand);
    }

    const importMyGlossaryOnClick = () => {
        if (!props.isOpen) {
            props.toggleSideBar();
        }
        fileUploadFieldRef.current && fileUploadFieldRef.current.click()
    }

    const handleMyglossaryOnClick = (glossary: qualityCheckMyGlossary) => {
        setSelectedGlossaryItem(glossary);
        setIsOpenEditGlossary(true);
    }

    const buildGlossaryOnClick = () => {
        if (!props.isOpen) {
            props.toggleSideBar();
        }
        setIsOpenBuildGlossaryPanel(true);
    }

    const handleMyGlossaryFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
        if (file) {
            const glossaryItems = await loadPairList(file[0]);
            setGlossaryList(glossaryItems)
            setGlossaryName(file[0].name)
            setIsOpenCreateGlossary(true)
            // const glossaryName = file[0].name;
            // await importMyGlossary({ glossaryName: glossaryName, glossaryItems: glossaryItems }).unwrap().then(() => {
            //     refetch();
            //     toast.success("My glossary import is successed");
            // }).catch((error) => {
            //     toast.error(error.data.message);
            // });
        }
    }

    const deleteConfirmDialog = () => {
        return (
            <Dialog
                open={isOpenDeleteConfirmDialog}
            >
                <DialogTitle>
                    {"Delete Glossary"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure to delete ${data?.filter((glossary) => glossary.myGlossaryId === selectedGlossaryId).map((glossary) => glossary.glossaryName)}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setIsOpenDeleteConfirmDialog(false) }}>
                        {"Cancel"}
                    </Button>
                    <Button onClick={() => { deleteMyGlossary({ myGlossaryId: selectedGlossaryId }); setIsOpenDeleteConfirmDialog(false) }}>
                        {"Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderListItem = (entry: [string, MainFunctionEnumSetting], index: number, action: Function) => {
        const func = entry[0];
        const setting = entry[1];

        return (
            <ListItem disablePadding key={index}>
                <ListItemButton
                    onClick={() => action(setting.link)}
                    sx={{
                        minHeight: 48,
                        justifyContent: props.isOpen ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor: location.pathname === setting.link ? "black" : "white",
                        ":hover": {
                            backgroundColor: "black",
                            "& .MuiListItemText-root": {
                                color: "white"
                            },
                            "& .MuiListItemIcon-root": {
                                color: "white"
                            }
                        }
                    }}
                    disabled={func === "Consistency Check" || func === "Accuracy Check" ? true : false}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: props.isOpen ? 3 : 'auto',
                            justifyContent: 'center',
                            color: location.pathname === setting.link ? "white" : "black"
                        }}
                    >
                        {setting.icon}
                    </ListItemIcon>
                    <ListItemText primary={func} sx={{ opacity: props.isOpen ? 1 : 0, color: location.pathname === setting.link ? "white" : "black" }} />
                </ListItemButton>
            </ListItem>
        )
    }

    const renderMainFunction = () => {

        const handleMainFunctionOnClick = (path: string) => {
            if (!props.isOpen) {
                props.toggleSideBar();
            }
            navigate(path);
        }

        return (
            <List>
                <TransitionGroup>
                    <Collapse>
                        {props.isOpen &&
                            <Slide direction={"right"} in={props.isOpen} mountOnEnter unmountOnExit >
                                <ListSubheader sx={{ fontWeight: "1000", color: "black", lineHeight: "25px" }}>
                                    {"Addcuracy Tools"}
                                </ListSubheader>
                            </Slide>}
                    </Collapse>
                    {Object.entries(mainFunctionEnumSettings).map((entry, index) => (
                        renderListItem(entry, index, handleMainFunctionOnClick)
                    ))}
                </TransitionGroup>
            </List>
        )
    }

    const renderGlossaryTools = () => {
        return (
            <List>
                {props.isOpen &&
                    <Slide direction={"right"} in={props.isOpen} mountOnEnter unmountOnExit>
                        <ListSubheader sx={{ opacity: props.isOpen ? 1 : 0, fontWeight: "1000", color: "black", lineHeight: "25px" }}>
                            {"Glossary Tools"}
                        </ListSubheader>
                    </Slide>
                }
                <ListItem disablePadding>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: props.isOpen ? 'initial' : 'center', px: 2.5 }} onClick={buildGlossaryOnClick}>
                        <ListItemIcon sx={{ minWidth: 0, mr: props.isOpen ? 3 : 'auto', justifyContent: 'center', color: "black" }}>
                            <ConstructionIcon style={{ height: "24px", width: "24px", color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="Build Glossary" sx={{ opacity: props.isOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton disabled={!isLoggedIn} sx={{ minHeight: 48, justifyContent: props.isOpen ? 'initial' : 'center', px: 2.5, paddingBottom: "0px", paddingTop: "0px" }} onClick={handleExpandMyGlossary}>
                        <ListItemIcon sx={{ minWidth: 0, mr: props.isOpen ? 3 : 'auto', justifyContent: 'center', color: "black" }}>
                            <BiBook style={{ height: "24px", width: "24px" }} />
                        </ListItemIcon>
                        <ListItemText primary="My Glossary" sx={{ opacity: props.isOpen ? 1 : 0 }} />
                        {props.isOpen && (isExpand ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                </ListItem>
                <Collapse in={isExpand} timeout="auto" unmountOnExit>
                    <List
                        sx={{
                            paddingTop: "0px",
                            maxHeight: "320px",
                            overflowY: "auto",
                            overflowX: "clip",
                            "::-webkit-scrollbar": { width: "5px", transform: "rotate(180deg)" },
                            "::-webkit-scrollbar-thumb": { background: "black" }
                        }}
                    >
                        <TransitionGroup>
                            {
                                data?.map((glossary: qualityCheckMyGlossary) => (
                                    <Collapse key={glossary.myGlossaryId}>
                                        <ListItem disablePadding >
                                            <ListItemButton sx={{ padding: "0px" }} onClick={() => handleMyglossaryOnClick(glossary)}>
                                                <ClearIcon sx={{ height: "15px", width: "15px", cursor: "pointer", margin: "0px 30px 0px 24px" }} onClick={(event) => { event.stopPropagation(); setIsOpenDeleteConfirmDialog(true); setSelectedGlossaryId(glossary.myGlossaryId) }} />
                                                <ListItemText
                                                    primary={glossary.glossaryName}
                                                    title={glossary.glossaryName}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Collapse>
                                ))
                            }
                        </TransitionGroup>

                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: props.isOpen ? 'initial' : 'center', px: 2.5 }} onClick={importMyGlossaryOnClick} disabled={!isLoggedIn}>
                        <ListItemIcon sx={{ minWidth: 0, mr: props.isOpen ? 3 : 'auto', justifyContent: 'center', color: "black" }}>
                            <FiUpload style={{ height: "24px", width: "24px", color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="Import Glossary" sx={{ opacity: props.isOpen ? 1 : 0 }} />
                    </ListItemButton>
                    <input hidden accept={".xlsx"} type="file" ref={fileUploadFieldRef} onChange={handleMyGlossaryFileUpload} onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => (event.target as HTMLInputElement).value = ""} />
                </ListItem>
            </List>)
    }

    const renderPayment = () => {

        const handlePaymentFunctionOnClick = (path: string) => {
            if (!props.isOpen) {
                props.toggleSideBar();
            }
            if (isLoggedIn) {
                navigate(path);
            } else {
                navigate("/login");
            }
        }

        return (
            <List>
                {props.isOpen &&
                    <Slide direction={"right"} in={props.isOpen} mountOnEnter unmountOnExit>
                        <ListSubheader sx={{ opacity: props.isOpen ? 1 : 0, fontWeight: "1000", color: "black", lineHeight: "25px" }}>
                            {"Payment"}
                        </ListSubheader>
                    </Slide>
                }
                {Object.entries(paymentPageSetting).map((entry, index) => (
                    renderListItem(entry, index, handlePaymentFunctionOnClick)
                ))}
            </List>
        )
    }

    return (
        <Drawer
            open={props.isOpen}
            variant="permanent"
            sx={{
                '& .MuiDrawer-paper': {
                    top: "66px"
                },
                //  position: "absolute"
            }}
        >
            {deleteConfirmDialog()}
            <EditGlossaryPanel
                isOpen={isOpenEditGlossary}
                glossary={selectedGlossaryItem}
                onClickClose={() => { setIsOpenEditGlossary(false); setSelectedGlossaryItem({ myGlossaryId: -1, glossaryName: "", glossaryItems: [] }) }}
            />
            <CreateGlossaryPanel
                isOpen={isOpenCreateGlossary}
                onClickClose={() => { setIsOpenCreateGlossary(false) }}
                glossary={glossaryList}
                expandMyGlossary={() => setIsExpand(true)}
                fileName={glossaryName}
                isBuildGlossary={false}
            />
            <BuildGlossaryPanel
                isOpen={isOpenBuildGlossaryPanel}
                onClose={() => { setIsOpenBuildGlossaryPanel(false) }}
                expandMyGlossary={() => setIsExpand(true)}
            />
            {renderMainFunction()}
            <Divider />
            {renderGlossaryTools()}
            <Divider />
            {renderPayment()}
        </Drawer>
    )
}

export default SideMenu;