export enum JobStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    RUNNING_RETRIEVE_DOC = 'RUNNING_RETRIEVE_DOC',
    COMPARE_DOC = 'COMPARE_DOC',
    RUNNING_ANNOTATE_DOC = 'RUNNING_ANNOTATE_DOC',
    COMPLETE = 'COMPLETE',
    PURGED = "PURGED",
    ARCHIVED = "ARCHIVED",
    ERROR = 'ERROR',
    FILE_FORMAT_ERROR = 'FILE_FORMAT_ERROR',
    SOURCE_FILE_FORMAT_ERROR = 'SOURCE_FILE_FORMAT_ERROR',
    TARGET_FILE_FORMAT_ERROR = 'TARGET_FILE_FORMAT_ERROR',
    SOURCE_LARGE_CONTENT_ERROR = 'SOURCE_LARGE_CONTENT_ERROR',
    TARGET_LARGE_CONTENT_ERROR = 'TARGET_LARGE_CONTENT_ERROR',
}